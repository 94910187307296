import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';

import ConversationCloud from '../images/conversationCloudDiagram.svg';
import PercentLinks from '../../components/PercentLinks';

import LayerAnalytics from '../images/layerAnalytics.svg';
import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerRealtime from '../images/layerRealtime.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ModernBuyersJourney from '../images/modernBuyersJourney.png';
import LibertyRing from '../animations/libertyRing.json';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'


import Default from '../../components/Layouts/Default';

var lottie;

export default class RevenueProcessAutomation extends Component {
  constructor(props) {
    super(props);
    this.animationIsAttached = false;
  }

  componentDidMount() {
    lottie = require('lottie-web');
    this.attachAnimation();
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing
      };

      lottie.loadAnimation(animationProperties);
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | What is Revenue Process Automation?</title>
          <meta
            name="description"
            content="With Revenue Process Automation, you can scale all of your core revenue generating activities, like activity tracking, sales coaching and pipeline management."
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                What Is Revenue Process Automation?
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                The New Way To Drive Consistent Sales Execution
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Automating The Bottom 90% Of Your Funnel Is Hard
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Complex Relationships:</b></u> today's recurring revenue relationships require team selling and high degrees of personalization.  Automation frameworks like sequencing don't work at this stage of the funnel.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Disparate Data</b></u>: SDRs use one software, AEs use another.  Finance and support don't track their emails/calls.  Some sales tools don't sync all the data to Salesforce.  All of these disparate data sources make it impossible to automate analysis and execution of your process, so you rely on managers to do this manually.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Impossible Calculations</b></u>: modeling your activity data on top of opportunities is hard enough.  Accounting for the dozens of edge cases (bad data, missing data, race conditions) is impossible, unless you have 5-10 specialized employees with advanced data analysis skills.
              </Large>
            </Box>
            <br/><br/><br/>
           <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                The Hyperautomation Solution: 
                <br/> 
                Capture {">>"} Calculate {">>"} Analyze {">>"} Automate
            </PageTitle2>
            <br/>
            <Box width={[1, 3/3]} mb={['32px', 0]}>
              <CenteredImg src={ActionEvent} alt="Salesforce process automation"/>

            </Box>
            <br/><br/><br/>
            <Box width={[1, 2/3]} mb={['32px', 0]}>
 
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Revenue Process Automation takes an entirely new approach to revenue management by tackling all of these data challenges directly in CRM.
                <br/><br/>
                By combining our expertise in data capture, transformation and Salesforce development, we've created a new way to build data pipelines in SFDC that you can use for real-time analysis and automations.
                <br/><br/>
              </Large>
            </Box>

            <br/><br/><br/>
          </Section>

        </WrapperSolid>

       <WrapperBackground background="wave" gradientType="dark">
        <PercentLinks/>
      </WrapperBackground>

        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
